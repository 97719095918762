import styled from 'styled-components';
import { StyledDivProps } from './types';

const getDynamicStyles = (props: StyledDivProps) => {
    if (!props.isAtRight) {
        return `
			position: relative;

			&::after {
					content: '';
					position: absolute;
					left: 93%;
					top: 0;
					height: 100%;
					width: 7%;
					background: linear-gradient(270deg, #f8f6fa 5.73%, rgba(248, 246, 250, 0) 100%);
					pointer-events: none; /* Allow clicks, scrolls, etc. to pass through */
					z-index: 3;
			}
`;
    }
    return ``;
};

export const StyledContainer = styled.div<StyledDivProps>`
    ${props => getDynamicStyles(props)}
`;
