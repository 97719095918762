import React, { FC } from 'react';
import cx from 'classnames';
import { RowHoverOption } from './types';

interface RowHoverOptionProps {
    rowHoverOptions: RowHoverOption[];
    hoverOptionsWidth: number;
    hoverOptionsRight: number;
    isContainerXOverflowing: boolean;
    className?: string;
}

const RowHoverOptions: FC<RowHoverOptionProps> = ({
    rowHoverOptions,
    hoverOptionsWidth,
    hoverOptionsRight,
    isContainerXOverflowing,
    className,
}) => {
    if (!rowHoverOptions) {
        return null;
    }

    return (
        <td
            style={{
                width: hoverOptionsWidth,
                right: hoverOptionsRight,
                background:
                    'linear-gradient(270deg, #FFF 0%, #FFF 80.73%, rgba(255, 255, 255, 0.00) 100%)',
            }}
            className={cx(
                'sticky opacity-0 transition-opacity duration-500 z-[302] border-b border-navy-10 h-full group-hover:opacity-100',
                className,
                {
                    '!absolute': !isContainerXOverflowing,
                },
            )}
        >
            <div className="flex h-full flex-col items-start justify-center pl-16 pr-4">
                {rowHoverOptions.map(({ buttonText, icon, onClick }) => (
                    <button
                        type="button"
                        className="text-navy-50 p-2 text-xs font-medium hover:text-navy"
                        key={buttonText.toString()}
                        onClick={event => {
                            event.stopPropagation(); // Prevent the row's onClick from being triggered
                            onClick(event);
                        }}
                    >
                        <div className="flex items-center justify-end gap-1">
                            {icon}
                            <div>{buttonText}</div>
                        </div>
                    </button>
                ))}
            </div>
        </td>
    );
};
export default RowHoverOptions;
