import React, { FC, useMemo } from 'react';
import dynamic from 'next/dynamic';
import { UserCircle } from 'phosphor-react';
import _ from 'lodash';
import useStore from '../../../../components/state/state';
import { filterValidPocsByStrategy } from '../../utils';
import HelmTooltip from '../../../../components/helmTooltip';
import { StrategyOverviewPartial } from '../../../../../../hooks/helm/strategyOverviewPartial';

const HelmMiniMapLoading = (
    <div className="relative size-20 overflow-hidden rounded-lg border bg-white" />
);

const HelmMiniMap = dynamic(() => import('../../../../components/map/helmMiniMap'), {
    loading: () => HelmMiniMapLoading,
    ssr: false,
});

interface NameElementProps {
    strategyId: number;
    strategyName: string;
    strategyCreatedBy: string;
    formattedPocLocations: StrategyOverviewPartial[];
    index: number;
}

export const NameElement: FC<NameElementProps> = ({
    index,
    formattedPocLocations,
    strategyCreatedBy,
    strategyId,
    strategyName,
}) => {
    const showCreatedBY = useStore(state => state.showCreatedBy);

    const createdBy = (
        <HelmTooltip
            multiline
            label={`Created by: ${strategyCreatedBy || 'unknown (legacy strategy)'}`}
            position="bottom"
        >
            <div className="size-5">
                <UserCircle size={20} />
            </div>
        </HelmTooltip>
    );

    const pocs = useMemo(
        () => filterValidPocsByStrategy(formattedPocLocations, strategyId),
        [strategyId, formattedPocLocations],
    );

    return (
        <div className="flex gap-2">
            <div className="w-20">
                <div className="!h-[80px] !w-[80px]">
                    {index > 6 ? (
                        HelmMiniMapLoading
                    ) : (
                        <HelmMiniMap
                            id={strategyId?.toString() || ''}
                            pocs={pocs}
                            className="!h-20 !w-20"
                            mapWrapperContainerClassName="!w-[120px] !h-[120px]"
                        />
                    )}
                </div>
            </div>
            {showCreatedBY && createdBy}
            <span className="text-xs font-medium text-navy">{strategyName}</span>
        </div>
    );
};
