import { Skeleton } from '@mantine/core';
import { FC } from 'react';
import { TaskRunningStatus } from '../../../../util/types';
import { useClientTranslation } from '../../../../../../hooks/helm/useClientTranslation';
import { strategyTimeStats } from '../../../../util/strategyUtil';
import HelmTooltip from '../../../../components/helmTooltip';
import { Badge } from '../../../../../../andromeda/Badge';
import { helmColors } from '../../../../util/colors';

interface StrategyTimeBadgeProps {
    taskStatus: TaskRunningStatus;
    taskStatusIsLoading: boolean;
    strategyStartDate: string;
    strategyEndDate: string;
    hasStrategyScheduledStatus: boolean;
}

export const StrategyTimeBadge: FC<StrategyTimeBadgeProps> = ({
    taskStatus,
    taskStatusIsLoading,
    strategyStartDate,
    strategyEndDate,
    hasStrategyScheduledStatus,
}) => {
    const { t } = useClientTranslation();
    const { remaining, strategyStartsInFuture } = strategyTimeStats(
        strategyStartDate,
        strategyEndDate,
    );

    if (taskStatusIsLoading) {
        return <Skeleton width={125} height={24} />;
    }
    if (taskStatus === 'running') {
        return (
            <HelmTooltip
                label="A preview will be generated, this will take approximately 5-10mins."
                position="left"
            >
                <Badge color={helmColors.purple} borderStyle="dotted">
                    {t('generating preview')}
                </Badge>
            </HelmTooltip>
        );
    }

    if (!hasStrategyScheduledStatus && strategyStartsInFuture) {
        return (
            <HelmTooltip label={t('preview-generated-tooltip')} position="left">
                <Badge color={helmColors.purple}>{t('preview generated')}</Badge>
            </HelmTooltip>
        );
    }
    if (hasStrategyScheduledStatus && strategyStartsInFuture) {
        return <Badge color={helmColors.tealGreen}>{t('scheduled')}</Badge>;
    }
    if (remaining === 0 && !strategyStartsInFuture) {
        return <Badge color={helmColors.primaryBlue}>{t('complete')}</Badge>;
    }
    return (
        <Badge color={helmColors.magenta}>
            {remaining} {t('day_left', { count: remaining })}
        </Badge>
    );
};
