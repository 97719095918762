import Orby from '../../../../utils/orby';

export const OneBrainOverlay = () => (
    <div className="flex items-center">
        <Orby
            size={28}
            style={{ mixBlendMode: 'darken' }}
            src="/videos/loader_sphere_light_mode_128x128_EDF2F7.mp4"
        />
        <div className="text-navy-70 ml-4 mr-6 text-[10px] font-semibold">Arena - One Brain</div>
    </div>
);
