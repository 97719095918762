import Overview from '../components/helm/pages/overview/Overview';
import HelmLayout from '../components/helm/helmLayout';
import HomePage from '../components/homePage';
import Layout from '../components/layout';

const Home = () => {
    if (process.env.NEXT_PUBLIC_URL.includes('neptune')) {
        return (
            <HelmLayout>
                <Overview />
            </HelmLayout>
        );
    }

    return (
        <Layout title="Fusion Home">
            <HomePage />
        </Layout>
    );
};

export default Home;
