import { useEffect, useState } from 'react';
import { useAppContext } from '../../../../../contexts/appContext';
import { getTaskGenerationStatus } from '../../../api/api';
import { StrategiesDataType } from '../../../util/types';
import { utcMoment } from '../../../util/util';

export const useStrategyTaskGenerationStatuses = (strategies: StrategiesDataType[] = []) => {
    const { user, environment } = useAppContext();
    const [taskGenerationStatuses, setTaskGenerationStatuses] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const filteredStrategies = strategies.filter(strategy => {
            const startDate = utcMoment(strategy.start_date).startOf('day');
            const today = utcMoment(new Date(Date.now())).startOf('day');
            const strategyStartsInFuture = startDate.isAfter(today);
            return strategyStartsInFuture;
        });

        const fetchTaskGenerationStatuses = async () => {
            setIsLoading(true);
            try {
                const promises = filteredStrategies.map(strategy =>
                    getTaskGenerationStatus(user?.token, strategy.strategy_id, environment),
                );

                const results = await Promise.all(promises);
                setTaskGenerationStatuses(results);
            } catch (err) {
                setError(`Error fetching task generation statuses: ${err.message}`);
            } finally {
                setIsLoading(false);
            }
        };

        if (filteredStrategies.length > 0) {
            fetchTaskGenerationStatuses();
        }
    }, [strategies, user, environment]);

    return { taskGenerationStatuses, error, taskGenerationStatusesIsLoading: isLoading };
};
