import React, { useState } from 'react';
import cx from 'classnames';
import { Tooltip } from '@mantine/core';
import { LockSimple } from 'phosphor-react';
import { colorsScheme } from '../constants/colors';

export type ToggleOption = {
    label: string;
    value: string;
    onClick?: () => void;
    tooltipLabel?: string;
    disabled?: boolean;
};

interface ToggleProps {
    options: ToggleOption[];
    selectedBgColor?: string;
    selectedTextColor?: string;
    textColor?: string;
    defaultOption: string | string[]; // Accepts either a single default option or an array
    onSelectionChange?: (selectedOptionValue: string | string[]) => void;
    variant?: 'primary' | 'secondary';
    disabled?: boolean;
    isMultiSelect?: boolean; // New prop to determine if multiple selections are allowed
    title?: string;
}

export const Toggle: React.FC<ToggleProps> = ({
    options,
    selectedBgColor = 'transparent',
    selectedTextColor = colorsScheme.light['navy-solid-70'],
    textColor = colorsScheme.light['navy-solid-30'],
    defaultOption,
    onSelectionChange,
    variant = 'secondary',
    disabled = false,
    isMultiSelect = false, // Default to false, making the component single-select by default
    title,
}) => {
    const initializeSelectedValue = () => {
        if (Array.isArray(defaultOption) && isMultiSelect) {
            return defaultOption.filter(opt => options.some(o => o.value === opt));
        }
        return typeof defaultOption === 'string' ? defaultOption : null;
    };

    const [selectedOptionValue, setSelectedOptionValue] = useState(initializeSelectedValue());

    const handleSelection = (selectedOptionVal: string, onClick: () => void) => {
        if (isMultiSelect) {
            if (Array.isArray(selectedOptionValue)) {
                const newValue = selectedOptionValue.includes(selectedOptionVal)
                    ? selectedOptionValue.filter(val => val !== selectedOptionVal)
                    : [...selectedOptionValue, selectedOptionVal];
                setSelectedOptionValue(newValue);
                onSelectionChange?.(newValue);
            }
        } else {
            setSelectedOptionValue(selectedOptionVal);
            onSelectionChange?.(selectedOptionVal);
        }
        onClick?.();
    };

    return (
        <div className="flex items-center gap-2">
            {title && <div className="text-xs font-medium text-navy-solid-70">{title}</div>}
            <div
                className={cx('flex items-center', {
                    'gap-1': isMultiSelect,
                })}
            >
                {options.map(
                    ({ label, value, onClick, tooltipLabel, disabled: optionDisabled }) => {
                        const isSelected = Array.isArray(selectedOptionValue)
                            ? selectedOptionValue.includes(value)
                            : selectedOptionValue === value;
                        return (
                            <button
                                disabled={disabled || optionDisabled}
                                type="button"
                                key={value}
                                className={cx('rounded-full cursor-pointer font-medium', {
                                    'hover:!text-navy-70':
                                        !isSelected && !disabled && !optionDisabled,
                                    'text-xs p-2': variant === 'secondary',
                                    'text-lg p-3': variant === 'primary',
                                    '!cursor-default': optionDisabled,
                                })}
                                style={{
                                    backgroundColor: isSelected ? selectedBgColor : 'transparent',
                                    color: isSelected ? selectedTextColor : textColor,
                                }}
                                onClick={() => handleSelection(value, onClick)}
                            >
                                <div className="flex items-center gap-1">
                                    {optionDisabled && (
                                        <LockSimple className="text-navy-solid-30" size={16} />
                                    )}
                                    {tooltipLabel && (
                                        <Tooltip
                                            label={tooltipLabel}
                                            classNames={{
                                                tooltip: '!z-[10001]',
                                            }}
                                            styles={{
                                                tooltip: {
                                                    backgroundColor: colorsScheme.light.navy,
                                                },
                                            }}
                                        >
                                            <div>{label}</div>
                                        </Tooltip>
                                    )}
                                    {!tooltipLabel && label}
                                </div>
                            </button>
                        );
                    },
                )}
            </div>
        </div>
    );
};
