/* eslint-disable @typescript-eslint/naming-convention */
import React, { useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { showNotification } from '@mantine/notifications';
import {
    ChartMetric,
    LegacyStrategyStatus,
    SortDirection,
    StatsMode,
    StrategyStatus,
    TaskGenerationStatus,
} from '../../../util/types';
import { capitalizeFirst, formatOverviewStrategiesData, utcMoment } from '../../../util/util';
import { useClientTranslation } from '../../../../../hooks/helm/useClientTranslation';
import useStore from '../../../components/state/state';
import { ClientOptions } from '../../../util/config';
import { strategyLeverSummary, strategyTimeStats } from '../../../util/strategyUtil';
import { helmColors } from '../../../util/colors';
import { PerformanceSummary } from '../StrategiesTable/TableElements/performanceSummary';
import { SimpleGridElement } from '../StrategiesTable/TableElements/simpleGridElement';
import { ProgressBar } from '../../../components/progressBar';
import { formatNumber } from '../../../../../utils/numberFormatHelpers';
import { LeverTooltip } from '../StrategiesTable/TableElements/leverTooltip';
import SortCaret from '../../../components/sortCaret/sortCaret';
import { GroupHeaders, HeadersType, RowType } from '../../../components/stickyTable/types';
import { ProductsCell } from '../../../components/productsCell';
import HelmTooltip from '../../../components/helmTooltip';
import { useStrategyTaskGenerationStatuses } from './useStrategyTaskGenenerationStatuses';
import {
    determineProgressDisplayColor,
    getHoverOptionsProps,
    getStrategyTableRowOnClick,
    sortStrategiesTableByColumn,
} from '../utils';
import {
    useDeleteStrategy,
    useStrategyOverviewList,
    useUnscheduleStrategy,
} from '../../../api/apiHooks';
import { StrategiesTableSortByType } from '../types';
import { MetricDisplay } from '../../../components/metricDisplay';
import { NameElement } from '../StrategiesTable/TableElements/nameElement';
import { queryClient } from '../../../../../utils/reactQueryConfig';
import { useAppContext } from '../../../../../contexts/appContext';
import { StrategyTimeBadge } from '../StrategiesTable/TableElements/strategyTimeBadge';
import { OrderedDisplay } from '../StrategiesTable/TableElements/orderedDisplay';
import useLayerPoints from '../../../util/mapUtils';

export const useStrategiesTableData = ({ statsMode }: { statsMode: StatsMode }) => {
    const { environment } = useAppContext();
    const { t, copyMap } = useClientTranslation();
    const client = useStore(state => state.client);
    const country = useStore(state => state.country);
    const demoMode = useStore(state => state.demoMode);

    const router = useRouter();
    const [sortBy, setSortBy] = useState<StrategiesTableSortByType | null>(null); // State to track the current sorting column
    const [sortOrder, setSortOrder] = useState<SortDirection>('asc'); // State to track the sorting order

    const { strategyOverviewListData, strategyOverviewListLoading, strategyOverviewListError } =
        useStrategyOverviewList();

    const { strategies, formattedPocLocations } = strategyOverviewListData || {};
    const layerPoints = useLayerPoints(formattedPocLocations);

    const formattedStrategies = useMemo(
        () => formatOverviewStrategiesData(strategies, environment, country, demoMode),
        [strategies, environment, country, demoMode],
    );
    const { taskGenerationStatuses, taskGenerationStatusesIsLoading } =
        useStrategyTaskGenerationStatuses(formattedStrategies);

    const [deletingId, setDeletingId] = useState(null);
    const { deleteStrategyIsLoading, deleteStrategyMutateAsync } = useDeleteStrategy({
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ['StrategyListOverview'],
            });
            showNotification({
                message: t('strategy deleted'),
                color: 'green',
            });
        },
        onError: () =>
            showNotification({
                message: t('error deleting strategy'),
                color: 'red',
            }),
        onMutate: ({ id }) => setDeletingId(id),
        onSettled: () => {
            setDeletingId(null);
        },
    });

    const { unschedule } = useUnscheduleStrategy();

    // Sort the table data based on the current sorting column and order
    const sortedStrategiesTable = sortBy
        ? sortStrategiesTableByColumn(formattedStrategies, sortOrder, sortBy)
        : formattedStrategies;

    const handleSort = (column: StrategiesTableSortByType) => {
        if (column !== sortBy) {
            setSortOrder('asc');
        }

        setSortBy(column);
        setSortOrder(prev => (prev === 'asc' ? 'desc' : 'asc'));
    };

    const tableRowData: RowType[] = useMemo(
        (): RowType[] =>
            sortedStrategiesTable?.map((strategyOverview, index) => {
                const {
                    revenue,
                    coverage,
                    mix,
                    average_order_value: averageOrderValue,
                    strategy_id: id,
                    num_orders: numOrders,
                    viewed,
                    ordered,
                    name,
                    goal,
                    num_accountids,
                    start_date,
                    end_date,
                } = strategyOverview;

                const { products, createdBy, status } = strategyOverview.data;

                const leverSummary = strategyLeverSummary();
                const { completedPercent, strategyStartsInFuture } = strategyTimeStats(
                    start_date,
                    end_date,
                );
                const taskGenerationStatus: TaskGenerationStatus =
                    taskGenerationStatuses.find(
                        taskGenStatus => String(taskGenStatus?.strategyId) === String(id),
                    ) || {};

                const hasStrategyScheduledStatus =
                    status === LegacyStrategyStatus.Scheduled ||
                    strategyOverview?.status === StrategyStatus.SCHEDULED;

                const rowOnClick = getStrategyTableRowOnClick(
                    strategyStartsInFuture,
                    taskGenerationStatus.status,
                    id,
                    router,
                );

                const hoverOptionsProps = getHoverOptionsProps(
                    t,
                    deletingId,
                    id,
                    deleteStrategyIsLoading,
                    deleteStrategyMutateAsync,
                    router,
                    strategyStartsInFuture,
                    unschedule,
                    hasStrategyScheduledStatus,
                );

                const progressDisplayColor = determineProgressDisplayColor(
                    completedPercent,
                    taskGenerationStatus.status,
                    hasStrategyScheduledStatus,
                    strategyStartsInFuture,
                );

                const columnElements = {
                    nameElement: (
                        <NameElement
                            strategyId={id}
                            strategyName={name}
                            strategyCreatedBy={createdBy}
                            formattedPocLocations={layerPoints}
                            index={index}
                        />
                    ),
                    goalElement: (
                        <span className="text-xs font-medium text-navy">
                            {capitalizeFirst(t(goal))}
                        </span>
                    ),
                    productsElement: (
                        <ProductsCell
                            strategyId={id}
                            strategyDataProducts={products}
                            targetClassName="!text-xs"
                        />
                    ),
                    performanceElement: (
                        <PerformanceSummary
                            goal={goal}
                            statsMode={statsMode}
                            statsData={{
                                coverage:
                                    statsMode === StatsMode.PerPoc
                                        ? coverage / num_accountids
                                        : coverage,
                                revenue:
                                    statsMode === StatsMode.PerPoc
                                        ? revenue / num_accountids
                                        : revenue,
                            }}
                        />
                    ),
                    startElement: (
                        <SimpleGridElement className="!mt-0 !text-xs">
                            {utcMoment(start_date).format('MMM D')}
                        </SimpleGridElement>
                    ),
                    endElement: (
                        <SimpleGridElement className="!mt-0 !text-xs">
                            {utcMoment(end_date).format('MMM D')}
                        </SimpleGridElement>
                    ),
                    timeframeCompletedElement: (
                        <SimpleGridElement className="!mt-0">
                            <div className="relative">
                                <div className="mb-1 flex items-center gap-2">
                                    <ProgressBar
                                        height={4}
                                        sections={[
                                            {
                                                percent: completedPercent,
                                                color: progressDisplayColor,
                                            },
                                        ]}
                                        background={helmColors.lightGray2}
                                    />
                                    <div
                                        className="text-xs"
                                        style={{
                                            color: progressDisplayColor,
                                        }}
                                    >
                                        {formatNumber(completedPercent, '0%')}
                                    </div>
                                </div>
                                <div className="absolute right-0 top-6 flex w-[180px] justify-end">
                                    <StrategyTimeBadge
                                        strategyStartDate={start_date}
                                        strategyEndDate={end_date}
                                        taskStatus={taskGenerationStatus.status}
                                        taskStatusIsLoading={
                                            strategyStartsInFuture &&
                                            taskGenerationStatusesIsLoading
                                        }
                                        hasStrategyScheduledStatus={hasStrategyScheduledStatus}
                                    />
                                </div>
                            </div>
                        </SimpleGridElement>
                    ),
                    pocsElement: (
                        <SimpleGridElement className="!mt-0">
                            <div className="space-x-1 align-baseline">
                                <span className="text-navy-70 text-xs">
                                    {formatNumber(num_accountids, '0,0')}
                                </span>
                                <span className="text-navy-30 text-[10px]">
                                    {t(copyMap['poc'])}s
                                </span>
                            </div>
                        </SimpleGridElement>
                    ),
                    leversElement: (
                        <SimpleGridElement className="!mt-0">
                            <div className="text-xs">
                                {Object.keys(leverSummary).map(lever => (
                                    <LeverTooltip
                                        key={lever}
                                        lever={lever}
                                        subLevers={leverSummary[lever]}
                                    />
                                ))}
                            </div>
                        </SimpleGridElement>
                    ),
                    revenueElement: (
                        <SimpleGridElement className="!mt-0 h-full">
                            <MetricDisplay
                                metric={ChartMetric.Revenue}
                                value={
                                    statsMode === StatsMode.PerPoc
                                        ? revenue / num_accountids
                                        : revenue
                                }
                                statsMode={statsMode}
                                valueClassName="!text-xs"
                                symbolContainerClassName="!text-[10px]"
                            />
                        </SimpleGridElement>
                    ),
                    coverageElement: (
                        <SimpleGridElement className="!mt-0 h-full">
                            <MetricDisplay
                                metric={ChartMetric.Coverage}
                                value={
                                    statsMode === StatsMode.PerPoc
                                        ? coverage / num_accountids
                                        : coverage
                                }
                                statsMode={statsMode}
                                valueClassName="!text-xs"
                                symbolContainerClassName="!text-[10px]"
                            />
                        </SimpleGridElement>
                    ),
                    mixElement: (
                        <SimpleGridElement className="!mt-0 h-full">
                            <MetricDisplay
                                metric={ChartMetric.Mix}
                                overview
                                value={mix}
                                statsMode={statsMode}
                                valueClassName="!text-xs"
                                symbolContainerClassName="!text-[10px]"
                            />
                        </SimpleGridElement>
                    ),
                    aovElement: (
                        <SimpleGridElement className="!mt-0 h-full">
                            <MetricDisplay
                                metric="revenue"
                                value={averageOrderValue}
                                valueClassName="!text-xs"
                                symbolContainerClassName="!text-[10px]"
                            />
                        </SimpleGridElement>
                    ),
                    ofOrdersElement: (
                        <SimpleGridElement className="!mt-0 h-full">
                            <MetricDisplay
                                value={
                                    statsMode === StatsMode.PerPoc
                                        ? numOrders / coverage
                                        : numOrders
                                }
                                valueClassName="!text-xs"
                                symbolContainerClassName="!text-[10px]"
                            />
                        </SimpleGridElement>
                    ),
                    viewedElement: (
                        <SimpleGridElement className="!mt-0 h-full">
                            <div className="text-navy-70 space-x-1 align-baseline text-xs">
                                {formatNumber(
                                    statsMode === StatsMode.PerPoc
                                        ? viewed / num_accountids
                                        : viewed,
                                    '0.0a',
                                )}
                            </div>
                        </SimpleGridElement>
                    ),
                    orderedElement: (
                        <OrderedDisplay
                            ordered={
                                statsMode === StatsMode.PerPoc ? ordered / num_accountids : ordered
                            }
                            viewed={
                                statsMode === StatsMode.PerPoc ? viewed / num_accountids : viewed
                            }
                        />
                    ),
                    growElement: (
                        <SimpleGridElement className="!mt-0">
                            <div className="text-navy-30 text-xs">{t('not-used')}</div>
                        </SimpleGridElement>
                    ),
                };

                const {
                    nameElement,
                    goalElement,
                    productsElement,
                    performanceElement,
                    startElement,
                    endElement,
                    timeframeCompletedElement,
                    pocsElement,
                    leversElement,
                    revenueElement,
                    coverageElement,
                    mixElement,
                    aovElement,
                    ofOrdersElement,
                    viewedElement,
                    orderedElement,
                    growElement,
                } = columnElements;

                return {
                    key: String(id),
                    columns: [
                        {
                            tableData: nameElement,
                        },
                        {
                            tableData: goalElement,
                        },
                        {
                            tableData: productsElement,
                            tableDataClassName: 'border-r',
                        },
                        {
                            tableData: performanceElement,
                        },
                        {
                            tableData: startElement,
                        },
                        {
                            tableData: endElement,
                        },
                        {
                            tableData: timeframeCompletedElement,
                        },
                        {
                            tableData: pocsElement,
                        },
                        {
                            tableData: leversElement,
                        },
                        {
                            tableData: revenueElement,
                            tableDataClassName: 'border-l',
                        },
                        {
                            tableData: coverageElement,
                        },
                        {
                            tableData: mixElement,
                        },
                        {
                            tableData: aovElement,
                            tableDataClassName: 'border-l',
                        },
                        {
                            tableData: ofOrdersElement,
                        },
                        {
                            tableData: viewedElement,
                            tableDataClassName: 'border-l',
                        },
                        {
                            tableData: orderedElement,
                        },
                        {
                            tableData: growElement,
                        },
                    ],
                    rowOnClick,
                    commonColumnClasses: 'bg-[#F9F8FA]',
                    hoverOptionsProps,
                };
            }),
        [
            sortedStrategiesTable,
            t,
            statsMode,
            router,
            copyMap,
            layerPoints,
            deleteStrategyIsLoading,
            deleteStrategyMutateAsync,
            deletingId,
            taskGenerationStatuses,
            unschedule,
            taskGenerationStatusesIsLoading,
        ],
    );

    const headers: HeadersType = useMemo(
        () => ({
            commonHeaderClasses: 'border-b bg-[#f9f8fa]',
            headersData: [
                { title: '', columnWidth: 235 },
                { title: '', columnWidth: 110 },
                { title: '', className: 'border-r', columnWidth: 118 },
                { title: '', columnWidth: 130 },
                {
                    title: t('start'),
                    columnWidth: 75,
                },
                {
                    title: t('end'),
                    columnWidth: 75,
                },
                { title: t('completed', { count: 1 }), columnWidth: 100 },
                { title: '', columnWidth: 110 },
                { title: '', columnWidth: 95 },
                { title: t('revenue'), className: 'border-l', columnWidth: 90 },
                { title: capitalizeFirst(t('coverage')), columnWidth: 90 },
                { title: capitalizeFirst(t('mix')), columnWidth: 90 },
                { title: t('aov'), className: 'border-l', columnWidth: 80 },
                { title: t('number-of-orders'), columnWidth: 110 },
                { title: t('viewed'), className: 'border-l', columnWidth: 75 },
                { title: t('ordered'), columnWidth: 100 },
                { title: '', columnWidth: 230 },
            ],
        }),
        [t],
    );

    const groupHeaders: GroupHeaders[] = (function () {
        const armsTitle = (
            <HelmTooltip
                withinPortal
                label={
                    <div className="flex flex-col text-center">
                        <div> {t('strategy-table-arms-title-tooltip-label-part1')}</div>
                        <div> {t('strategy-table-arms-title-tooltip-label-part2')}</div>
                    </div>
                }
            >
                <div className="cursor-pointer">{t('Arms')}</div>
            </HelmTooltip>
        );

        return [
            {
                stickyColumnsCountData: 1,
                commonHeaderClasses: 'font-normal bg-[#f9f8fa]',
                headersData: [
                    {
                        title: t('details'),
                        span: 3,
                    },
                    { title: '', span: 6 },
                    {
                        title: t('status'),
                        span: client === ClientOptions.AlphaC ? 4 : 3,
                        className: 'border-l',
                    },
                    {
                        title: '',
                        span: client === ClientOptions.AlphaC ? 3 : 2,
                        className: client === ClientOptions.AlphaC ? 'border-l' : undefined,
                    },
                    {
                        title: armsTitle,
                        span: 3,
                        className: 'border-l',
                    },
                ],
            },
            {
                stickyColumnsCountData: 3,
                commonHeaderClasses: 'bg-navy-5 text-navy-50 border-t border-b',
                headersData: [
                    {
                        title: (
                            <div className="flex items-center gap-2">
                                <span>{t('name')}</span>
                                <SortCaret
                                    sortOrder={sortOrder}
                                    selectedSortBy={sortBy}
                                    sortBy="name"
                                    onClick={() => handleSort('name')}
                                />
                            </div>
                        ),
                        span: 1,
                    },
                    { title: t('goal'), span: 1 },
                    {
                        title: (
                            <HelmTooltip
                                withinPortal
                                label={
                                    <div>
                                        <div>
                                            {t('strategy-table-focus-skus-title-tooltip-label_1')}
                                        </div>
                                        <div>
                                            {t('strategy-table-focus-skus-title-tooltip-label_2')}
                                        </div>
                                    </div>
                                }
                            >
                                <div className="cursor-pointer">
                                    {capitalizeFirst(t('focus sku', { count: 2 }))}
                                </div>
                            </HelmTooltip>
                        ),
                        span: 1,
                        className: 'border-r',
                    },
                    { title: t('performance'), span: 1 },
                    { title: t('timeframe'), span: 3 },
                    {
                        title: `${capitalizeFirst(t(copyMap['poc']))}s`,
                        span: 1,
                    },
                    { title: armsTitle, span: 1 },
                    {
                        title: t('metric', { count: 2 }),
                        span: client === ClientOptions.AlphaC ? 4 : 3,
                        className: 'border-l',
                    },
                    {
                        title: '',
                        span: client === ClientOptions.AlphaC ? 3 : 2,
                        className: 'border-l',
                    },
                    {
                        title: `${t('customerApp')} - ${t('overall')}`,
                        span: 2,
                        className: 'border-l',
                    },
                    { title: t('Grow'), span: 1 },
                ],
            },
        ];
    })();

    const strategiesLength = strategies?.length;

    return {
        headers,
        tableRowData,
        groupHeaders,
        strategiesDataLoading: strategyOverviewListLoading,
        strategiesDataError: strategyOverviewListError,
        strategiesLength,
        containerHeight: strategyOverviewListLoading || strategiesLength === 0 ? '70vh' : 'auto',
    };
};
