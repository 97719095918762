import { CaretDown, CaretUp } from 'phosphor-react';
import React, { FC } from 'react';
import cx from 'classnames';
import { SortDirection } from '../../util/types';

interface SortCaretProps {
    className?: string;
    sortOrder: SortDirection;
    selectedSortBy: string;
    sortBy: string;
    onClick?: React.MouseEventHandler<SVGSVGElement>;
}

const SortCaret: FC<SortCaretProps> = ({
    className,
    sortOrder,
    selectedSortBy,
    sortBy,
    onClick,
}) => {
    const caretDownElement = (
        <CaretDown
            className={cx('text-navy-70 cursor-pointer', className)}
            size={12}
            onClick={onClick}
        />
    );
    if (selectedSortBy !== sortBy) {
        return caretDownElement;
    }

    if (sortOrder === 'asc') {
        return caretDownElement;
    }

    return (
        <CaretUp
            className={cx('text-navy-70 cursor-pointer', className)}
            size={12}
            onClick={onClick}
        />
    );
};

export default SortCaret;
