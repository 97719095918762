import React from 'react';
import dynamic from 'next/dynamic';
import { StrategiesTable } from './StrategiesTable/StrategiesTable';
import Header from './Header';
import Footer from './Footer';

const HelmMapOffset = dynamic(() => import('./components/helmMapOffset'), { ssr: false });

const Overview = () => (
    <div className="relative w-full overflow-hidden">
        <HelmMapOffset />
        <Header />
        <StrategiesTable />
        <Footer />
    </div>
);

export default Overview;
