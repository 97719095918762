import { useMemo } from 'react';
import HelmTooltip from '../../../../components/helmTooltip';
import { useClientTranslation } from '../../../../../../hooks/helm/useClientTranslation';

interface LeverTooltipProps {
    lever: string;
    subLevers: string[];
}

export const LeverTooltip = ({ lever, subLevers }: LeverTooltipProps) => {
    const { t } = useClientTranslation();
    const label = useMemo(
        () => (
            <div>
                <div className="opacity-80">{t(lever)}:</div>
                {subLevers.map(subLever => (
                    <div key={subLever}>{subLever}</div>
                ))}
            </div>
        ),
        [lever, subLevers, t],
    );
    return (
        <HelmTooltip label={label} position="bottom">
            <div className="truncate">{t(lever)}</div>
        </HelmTooltip>
    );
};
