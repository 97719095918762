import { utcMoment } from './util';

export const strategyTimeStats = (startDateParam: string, endDateParam: string) => {
    const startDate = utcMoment(startDateParam).startOf('day');
    const endDate = utcMoment(endDateParam).startOf('day');
    const totalDays = endDate.diff(startDate, 'days') + 1;
    const today = utcMoment(new Date(Date.now())).startOf('day');
    const strategyStartsInFuture = startDate.isAfter(today);
    const completed = endDate.isBefore(today)
        ? endDate.diff(startDate, 'days') + 1
        : today.diff(startDate, 'days');
    const completedPercent = strategyStartsInFuture ? 0 : completed / totalDays;
    let remaining = endDate.isAfter(today) ? Math.abs(today.diff(endDate, 'days') - 1) : 0;

    if (endDate.isSame(today)) {
        remaining = 1;
    }

    return {
        startDate,
        endDate,
        totalDays,
        completed,
        completedPercent,
        remaining,
        today,
        strategyStartsInFuture,
    };
};

const leverDisplayMap = {
    'BEES Force': 'Force',
    'BEES Grow': 'Grow',
    'BEES Customer': 'Customer',
};

const hardCodedStrategyLevers = {
    'BEES Grow': {
        'POC Prioritization': false,
        'Mission Prioritization': false,
        'AI-Generated Call Scripts': false,
    },
    'BEES Force': {
        'POC Visits': false,
        'Rep Routing': false,
        'Rep Tasking': true,
        'AI-Generated Selling Arguments': false,
    },
    'BEES Customer': {
        Earn: false,
        Email: false,
        Promos: false,
        Redeem: false,
        Challenge: false,
        'Top Deals': true,
        'Banner Image': false,
        'Upsell Popup': true,
        'Forgotten Items': true,
        'Suggested Order': true,
        'In-App Messaging': true,
        'Push Notification': true,
        'Text Message / WhatsApp': false,
        'Instagram / Facebook Ads': false,
    },
};

export const strategyLeverSummary = () => {
    const levers = hardCodedStrategyLevers;
    return Object.keys(levers || {}).reduce((acc, lever) => {
        if (Object.values(levers[lever]).some(val => val)) {
            acc[leverDisplayMap[lever]] = Object.entries(levers[lever])
                .filter(([, val]) => val)
                .map(([key]) => key);
        }
        return acc;
    }, {});
};
