import cx from 'classnames';
import { useEffect } from 'react';
import { Card } from '../../../../../andromeda/Card';
import useStore from '../../../components/state/state';
import { helmColors } from '../../../util/colors';
import { useAppContext } from '../../../../../contexts/appContext';

declare const heap;

export const DisabledPlaceholder = () => {
    const client = useStore(state => state.client);
    const { user } = useAppContext();
    useEffect(() => {
        heap.track('User Visited BEES.AI for ABI', { userGroup: user.groups });
    }, [user.groups]);
    return (
        <div
            id="disabled-abi"
            className="absolute top-[20%]  z-50 flex w-full flex-col items-center"
        >
            <Card
                withDivider={false}
                className={cx('w-5/6 4xl:w-auto flex justify-center items-center h-[200px]')}
            >
                <div className="mb-4 text-center">
                    BEES.ai for {client} is currently under maintenance.
                </div>
                <div>
                    If you have any questions, please email us at{' '}
                    <a
                        style={{ color: helmColors.darkBlue }}
                        href="mailto:onebrain-support@arena-ai.com"
                    >
                        onebrain-support@arena-ai.com
                    </a>{' '}
                    for assistance.
                </div>
            </Card>
        </div>
    );
};
