import { useCallback, useRef } from 'react';
import { Skeleton } from '@mantine/core';
import HelmTooltip from './helmTooltip';

interface ProgressBarProps {
    sections: { percent: number; color: string; tooltipLabel?: string | JSX.Element }[];
    secondarySections?: { percent: number; color: string; tooltipLabel?: string | JSX.Element }[];
    className?: string;
    height?: number;
    background?: string;
    loading?: boolean;
}
export const ProgressBar = ({
    sections,
    className,
    secondarySections, // TODO: this prop is never used, but removing it breaks the styles
    height = 8,
    background = 'white',
    loading,
}: ProgressBarProps) => {
    const containerRef = useRef(null);
    const getRoundedClass = useCallback((index, total) => {
        const isFirst = index === 0;
        const isLast = index === total - 1;
        if (isFirst && isLast) return 'rounded-sm';
        if (isFirst) return 'rounded-l-sm';
        if (isLast) return 'rounded-r-sm';
        return '';
    }, []);

    const renderSections = useCallback(
        () =>
            sections.map(({ color, percent, tooltipLabel }, index) => {
                const coloredSection = (
                    <div
                        key={color}
                        className={`${getRoundedClass(index, sections.length)}`}
                        style={{
                            height: `${height}px`,
                            backgroundColor: color,
                            width: `${percent * 100}%`,
                        }}
                    />
                );
                if (tooltipLabel) {
                    return (
                        <HelmTooltip label={tooltipLabel} key={color}>
                            {coloredSection}
                        </HelmTooltip>
                    );
                }
                return coloredSection;
            }),
        [getRoundedClass, height, sections],
    );
    const renderSecondarySections = useCallback(
        () =>
            secondarySections &&
            secondarySections.map((section, index) => (
                <div
                    key={section.color}
                    className={`${getRoundedClass(index, secondarySections.length)}`}
                    style={{
                        height: `${height}px`,
                        backgroundColor: section.color,
                        width: `${section.percent * 100}%`,
                    }}
                />
            )),
        [getRoundedClass, height, secondarySections],
    );

    if (loading) {
        return <Skeleton className="w-full" height={height} />;
    }
    return (
        <div
            style={{ height: `${height}px`, background }}
            className={`relative w-full rounded  ${className}`}
            ref={containerRef}
        >
            <div style={{ height: `${height}px` }} className="left-0 top-0 flex w-full space-x-px">
                {renderSecondarySections()}
            </div>
            <div
                style={{ height: `${height}px`, marginTop: `-${height}px` }}
                className="left-0 top-0 flex w-full space-x-px"
            >
                {renderSections()}
            </div>
        </div>
    );
};
