import React, { CSSProperties, forwardRef, ReactNode } from 'react';
import cx from 'classnames';
import colorLib from 'color';

const getOpaqueColor = (hexColorParam: string, opacity: number) => {
    // Convert hex to RGB
    const [r, g, b] = colorLib(hexColorParam).array() as [number, number, number];

    // Return the RGBA color
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

interface BadgeProps {
    children: ReactNode;
    color: string;
    className?: string;
    style?: CSSProperties;
    borderStyle?:
        | 'none'
        | 'hidden'
        | 'dotted'
        | 'dashed'
        | 'solid'
        | 'double'
        | 'groove'
        | 'ridge'
        | 'inset'
        | 'outset';
    bgColorOpacity?: number;
}

export const Badge = forwardRef<HTMLDivElement, BadgeProps>(
    ({ children, className, style, color, bgColorOpacity = 0.1, borderStyle = 'none' }, ref) => {
        const badgeStyle: CSSProperties = {
            ...style,
            color,
            borderStyle,
            backgroundColor: getOpaqueColor(color, bgColorOpacity),
            borderColor: color,
        };

        const badgeClasses = cx(
            'border py-1 px-2 rounded-full text-xs font-medium w-fit ',
            className,
        );

        return (
            <div ref={ref} style={badgeStyle} className={badgeClasses}>
                {children}
            </div>
        );
    },
);
