import { useCallback, useMemo } from 'react';
import { Skeleton } from '@mantine/core';
import cx from 'classnames';
import { formatNumber } from '../../../utils/numberFormatHelpers';
import { useClientTranslation } from '../../../hooks/helm/useClientTranslation';
import { ChartMetric, StatsMode } from '../util/types';

interface MetricDisplayProps {
    metric?: ChartMetric | string;
    value: number;
    size?: string;
    statsMode?: StatsMode;
    full?: boolean;
    overview?: boolean;
    loading?: boolean;
    skeletonWidth?: number;
    skeletonHeight?: number;
    noFormat?: boolean;
    valueClassName?: string;
    symbolContainerClassName?: string;
}

export const MetricDisplay = ({
    metric,
    statsMode,
    value,
    size = 'text-3xl',
    full,
    overview,
    loading,
    skeletonWidth = 80,
    skeletonHeight = 40,
    noFormat,
    valueClassName,
    symbolContainerClassName,
}: MetricDisplayProps) => {
    const { t, copyMap } = useClientTranslation();
    const metricSymbolMap = useMemo(
        () => ({
            [ChartMetric.Revenue]: '$',
            minutes: t('mins'),
            tasks: t('tasks'),
            coverage: `${t(copyMap['poc'])}s`,
            mix: 'SKUs',
            mixOverview: `SKUs/${t(copyMap['poc'])}`,
            impressions: t('impressions').toLowerCase(),
            visit_duration_seconds: t('minutes'),
        }),
        [copyMap, t],
    );

    const abbreviate = value < 100;
    const symbolTextSize = (function () {
        if (size === 'text-sm' || size === 'text-xs') return 'text-xs';
        return 'text-base';
    })();

    const metricDisplay = useMemo(() => {
        if (overview && metric === ChartMetric.Mix) {
            return metricSymbolMap[`${metric}Overview`];
        }
        return metricSymbolMap[metric];
    }, [metric, metricSymbolMap, overview]);

    const symbolContainer = (
        <span className={cx(`${symbolTextSize} text-navy-30`, symbolContainerClassName)}>
            {metricDisplay}
        </span>
    );

    const formatMetricValue = useCallback(
        (val: number) => {
            if (noFormat) return val;
            if (metric === ChartMetric.Coverage) {
                if (statsMode === StatsMode.PerPoc) {
                    return formatNumber(val, '0.00%'); // per poc coverage is a percentage (what percentage of pocs have purchased)
                }
                return formatNumber(val, '0,0'); // this is always an integer, should not need two decimals of precision
            }
            if (full) {
                if (val < 1000) return formatNumber(val, '0,0.00');
                return formatNumber(val, '0,0');
            }
            return abbreviate ? formatNumber(val, '0.00a') : formatNumber(val, '0.00a');
        },
        [abbreviate, full, metric, statsMode, noFormat],
    );

    if (loading) {
        return <Skeleton width={skeletonWidth} height={skeletonHeight} />;
    }

    return (
        <div className="space-x-1 align-baseline">
            {metric === ChartMetric.Revenue && symbolContainer}
            <span className={cx(`${size} text-navy-70`, valueClassName)}>
                {formatMetricValue(value)}
            </span>
            {metric !== ChartMetric.Revenue && symbolContainer}
        </div>
    );
};
