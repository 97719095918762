import React from 'react';
import Image from 'next/image';
import { capitalize } from 'lodash';
import { LogoBreadcrumbsContainer } from '../../components/layout/logoBreadcrumbsContainer';
import useStore from '../../components/state/state';
import { getGreeting } from '../../util/util';
import { useClientTranslation } from '../../../../hooks/helm/useClientTranslation';
import { useAppContext } from '../../../../contexts/appContext';
import { ClientOptions } from '../../util/config';

const Header = () => {
    const { t } = useClientTranslation();
    const { demoMode, client } = useStore(state => ({
        demoMode: state.demoMode,
        client: state.client,
    }));
    const {
        user: { firstName },
    } = useAppContext();
    const formattedFirstName = firstName.split(/\s+|-/).map(capitalize).join(' ');

    return (
        <>
            <div className="absolute top-0 z-50">
                <LogoBreadcrumbsContainer
                    extraContent={
                        <div className="text-navy-70 text-lg">
                            <div className="font-normal">
                                {t(getGreeting())}
                                {firstName ? `, ${formattedFirstName}` : ''}
                            </div>
                        </div>
                    }
                />
            </div>
            <div className="absolute right-4 top-4 z-[1000] mr-6 flex items-center">
                <div className={`opacity-100 ${demoMode ? '!opacity-0' : ''}`}>
                    {client === ClientOptions.Neptune && (
                        <Image
                            alt="arena"
                            src="/images/nestleLogo.svg"
                            height={16}
                            width={58}
                            className="mr-2"
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default Header;
