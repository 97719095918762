import React, { useState } from 'react';
import { Card } from '@mantine/core';
import styled from 'styled-components';
import cx from 'classnames';
import { useClientTranslation } from '../../../../../hooks/helm/useClientTranslation';
import StickyTable from '../../../components/stickyTable/StickyTable';
import { useStrategiesTableData } from '../hooks/useStrategiesTableData';
import { StatsMode } from '../../../util/types';
import { StrategiesTableHeader } from './StrategiesTableHeader';
import { DisabledPlaceholder } from './disabledPlaceholder';
import useStore from '../../../components/state/state';
import { ClientOptions } from '../../../util/config';

const StyledCard = styled(Card)`
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),
        linear-gradient(112.87deg, rgba(249, 249, 249, 0.5) 0%, rgba(240, 234, 243, 0.5) 100%);
    border-width: 0.5px 0px 0px 0.5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: 8px 8px 16px rgba(10, 12, 15, 0.05);
    backdrop-filter: blur(8px);
    border-radius: 4px;
    padding: 0 !important;
    margin-top: 12px;
`;

export const StrategiesTable = () => {
    const { t } = useClientTranslation();
    const [statsMode, setStatsMode] = useState<StatsMode>(StatsMode.PerPoc);
    const client = useStore(state => state.client);

    const {
        headers,
        tableRowData,
        groupHeaders,
        strategiesDataLoading,
        strategiesLength,
        strategiesDataError,
        containerHeight,
    } = useStrategiesTableData({
        statsMode,
    });

    if (client === ClientOptions.AlphaC) {
        return <DisabledPlaceholder />;
    }

    return (
        <div className="absolute top-[10%] z-50 flex w-full flex-col items-center">
            <div
                className={cx('w-5/6 min-[2347px]:w-auto', {
                    '4xl:!w-1/2': strategiesDataLoading,
                })}
            >
                <StrategiesTableHeader statsMode={statsMode} setStatsMode={setStatsMode} />
                <StyledCard>
                    <StickyTable
                        error={strategiesDataError}
                        loading={strategiesDataLoading}
                        isDataEmpty={strategiesLength === 0}
                        noDataMessage={t('no-strategies-message')}
                        headers={headers}
                        rows={tableRowData}
                        stickyColumnsCount={3}
                        groupHeaders={groupHeaders}
                        headerHeight={38}
                        hoveredRowBgColor="#fff"
                        containerHeight={containerHeight}
                        containerClassName="!max-h-[70vh]"
                    />
                </StyledCard>
            </div>
        </div>
    );
};
