import React from 'react';
import { OneBrainOverlay } from './components/oneBrainOverlay';
import { PoweredByArenaOverlay } from '../../components/poweredByArenaOverlay';

const Footer = () => (
    <div className="absolute bottom-6 flex w-full items-center justify-between pl-4 pr-20">
        <OneBrainOverlay />
        <PoweredByArenaOverlay />
    </div>
);

export default Footer;
