import * as React from 'react';

type Callback = (hasOverflow: boolean) => void;

const useIsOverflow = (
    ref: React.RefObject<HTMLElement>,
    isVerticalOverflow: boolean,
    dependencies: React.DependencyList = [],
    callback?: Callback,
): boolean | undefined => {
    const [isOverflow, setIsOverflow] = React.useState<boolean | undefined>(undefined);

    React.useLayoutEffect(() => {
        const { current } = ref;
        if (current) {
            const { clientWidth, scrollWidth, clientHeight, scrollHeight } = current;

            const trigger = () => {
                const hasOverflow = isVerticalOverflow
                    ? scrollHeight > clientHeight
                    : scrollWidth > clientWidth;

                setIsOverflow(hasOverflow);

                if (callback) callback(hasOverflow);
            };

            trigger();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callback, ref, isVerticalOverflow, ...dependencies]);

    return isOverflow;
};

export default useIsOverflow;
