import { useMemo } from 'react';
import { SimpleGridElement } from './simpleGridElement';
import { formatNumber } from '../../../../../../utils/numberFormatHelpers';
import HelmTooltip from '../../../../components/helmTooltip';
import { useClientTranslation } from '../../../../../../hooks/helm/useClientTranslation';

interface OrderedDisplayProps {
    ordered: number;
    viewed: number;
}

export const OrderedDisplay = ({ ordered, viewed }: OrderedDisplayProps) => {
    const { t } = useClientTranslation();
    const percentOrdered = useMemo(() => {
        if (!ordered) return 0;
        return ordered / viewed;
    }, [ordered, viewed]);

    return (
        <SimpleGridElement className="!mt-0 h-full">
            <div className="flex space-x-2">
                <div className="text-navy-70 text-xs">
                    {formatNumber(ordered, ordered < 10 ? '0.00a' : '0.0a')}
                </div>
                <HelmTooltip zIndex={304} label={t('conversion-rate-tooltip')}>
                    <span className="text-navy-50 text-xs">
                        ({formatNumber(percentOrdered, '0.0%')})
                    </span>
                </HelmTooltip>
            </div>
        </SimpleGridElement>
    );
};
