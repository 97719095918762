import { Title } from '@mantine/core';
import { useRouter } from 'next/router';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { ArrowRight } from 'phosphor-react';
import { useClientTranslation } from '../../../../../hooks/helm/useClientTranslation';
import { StatsMode } from '../../../util/types';
import { HelmRoutes } from '../../../util/routes';
import { helmColors } from '../../../util/colors';
import { PrimaryButton } from '../../../components/buttons/primaryButton';
import { Toggle } from '../../../../../andromeda/Toggle';
import { getStatsModeToggleOptions } from '../constants';

interface StrategiesTableHeaderProps {
    statsMode: StatsMode;
    setStatsMode: Dispatch<SetStateAction<StatsMode>>;
}

export const StrategiesTableHeader: FC<StrategiesTableHeaderProps> = ({
    statsMode,
    setStatsMode,
}) => {
    const { t } = useClientTranslation();
    const router = useRouter();
    const handleStatsModeChange = (selectedStatsMode: StatsMode) => {
        setStatsMode(selectedStatsMode);
    };

    const statsModeToggleOptions = getStatsModeToggleOptions(t);
    return (
        <div className="flex items-center justify-between p-4">
            <Title className="!text-navy-70 !font-manrope !text-[22px] !font-normal" order={3}>
                {t('your strategies')}
            </Title>
            <div className="flex items-center space-x-4">
                <div className="flex justify-end">
                    <div className="flex items-center space-x-2">
                        <div className="text-sm font-medium text-navy">
                            {t('overtime metrics')}:
                        </div>
                        <div>
                            <Toggle
                                options={statsModeToggleOptions}
                                onSelectionChange={handleStatsModeChange}
                                selectedBgColor="#d8d9f1"
                                selectedTextColor={helmColors.darkGreyBlue}
                                textColor={helmColors.gray1}
                                defaultOption={statsMode}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <PrimaryButton
                        className="!text-navy-15 !font-semibold"
                        variant="primary"
                        onClick={() => router.push(HelmRoutes.CREATE_STRATEGY(router.query))}
                        rightSection={<ArrowRight size={20} color={helmColors.lightGray2} />}
                    >
                        {t('create new strategy')}
                    </PrimaryButton>
                </div>
            </div>
        </div>
    );
};
