import { ChartMetric, StatsMode } from '../../util/types';

export const metricGoalMap = {
    'Grow Volume': 'volume',
    'Increase Coverage': ChartMetric.Coverage,
    'Grow Revenue': ChartMetric.Revenue,
};

export const getStatsModeToggleOptions = (t: any) => [
    {
        label: t(StatsMode.PerPoc),
        value: StatsMode.PerPoc,
    },
    {
        label: t(StatsMode.AllPocs),
        value: StatsMode.AllPocs,
    },
];
