import { useMemo } from 'react';
import { HoverCard } from '@mantine/core';
import { Gauge } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { helmColors } from '../../../../util/colors';
import { capitalizeFirst, metricDisplayMap } from '../../../../util/util';
import { MetricDisplay } from '../../../../components/metricDisplay';
import { StatsMode } from '../../../../util/types';
import useStore from '../../../../components/state/state';
import { ClientOptions } from '../../../../util/config';

const defaultStatus = {
    title: 'No target set',
    chartColor: helmColors.lightGray,
    textColor: helmColors.gray1,
    icon: <Gauge color={helmColors.gray1} />,
};
const targetStatusMap = {
    A: {
        chartColor: helmColors.lightGreen,
        textColor: helmColors.green,
        icon: <Gauge color={helmColors.green} />,
    },
    B: {
        chartColor: helmColors.yellow,
        textColor: helmColors.yellow,
        icon: <Gauge color={helmColors.yellow} />,
    },
    C: {
        chartColor: helmColors.crimson,
        textColor: helmColors.crimson,
        icon: <Gauge color={helmColors.crimson} />,
    },
    D: {
        chartColor: helmColors.lightGreen,
        textColor: helmColors.darkBlue,
        icon: <span>&#128640;</span>, // rocket emoji
    },
};

interface PerformanceSummaryProps {
    goal: string;
    statsMode: StatsMode;
    statsData: {
        revenue: number;
        coverage: number;
    };
}

export const PerformanceSummary = ({ statsMode, goal, statsData }: PerformanceSummaryProps) => {
    const { t } = useTranslation();
    const metricDisplay = useMemo(() => capitalizeFirst(t(metricDisplayMap[goal])), [goal, t]);
    const client = useStore(state => state.client);

    const revenuePart = useMemo(
        () => (
            <div className="w-20">
                <div className="ml-2 mt-0">
                    <div className="text-xs" style={{ color: helmColors.gray2 }}>
                        {metricDisplay}
                    </div>
                    <MetricDisplay
                        metric={goal}
                        value={statsData[goal]}
                        size="text-base"
                        statsMode={statsMode}
                        valueClassName="!text-xs"
                        symbolContainerClassName="!text-[10px]"
                    />
                </div>
            </div>
        ),
        [statsData, goal, metricDisplay, statsMode],
    );

    return (
        <HoverCard shadow="md" position="right" disabled={client === ClientOptions.Neptune}>
            <HoverCard.Target>{revenuePart}</HoverCard.Target>
            <HoverCard.Dropdown
                className="!pointer-events-none !border"
                style={{
                    borderWidth: '0.5px 0px 0px 0.5px',
                    borderStyle: 'solid',
                    borderColor: 'rgba(0, 0, 0, 0.1)',
                    boxShadow: '8px 8px 16px rgba(10, 12, 15, 0.05)',
                    background:
                        'linear-gradient(112.87deg, rgba(249, 249, 249, 1.0) 0%, rgba(240, 234, 243, 1.0) 100%)',
                }}
            />
        </HoverCard>
    );
};
