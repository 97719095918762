/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React, { CSSProperties, useState } from 'react';
import cx from 'classnames';
import { useViewportSize } from '@mantine/hooks';
import { GroupHeader, GroupHeaders, HeadersType, RowType } from './types';
import RowHoverOptions from './RowHoverOptions';
import ErrorText from '../../../utils/errorText';
import useIsOverflow from '../../../../hooks/helm/useIsOverflow';
import { useRightShadow } from '../../../../hooks/helm/useRightShadow';
import { StyledContainer } from './sticky.table.styled.components';
import { Spinner } from '../../../../andromeda/Spinners/spinner';

interface StickyTableProps {
    headers: HeadersType;
    rows: RowType[];
    stickyColumnsCount: number;
    headerHeight: number;
    loading: boolean;
    isDataEmpty: boolean;
    error: Error | undefined;
    groupHeaders?: GroupHeaders[];
    noDataMessage?: string;
    hoveredRowBgColor?: string;
    containerHeight?: CSSProperties['height'];
    containerClassName?: string;
}

const StickyTable: React.FC<StickyTableProps> = ({
    loading,
    isDataEmpty,
    noDataMessage,
    error,
    headers,
    rows,
    stickyColumnsCount,
    groupHeaders,
    headerHeight,
    hoveredRowBgColor,
    containerHeight = '60vh',
    containerClassName,
}) => {
    const columnWidths = headers.headersData.map(({ columnWidth }) => columnWidth);
    const [hoveredRowIndex, setHoveredRowIndex] = useState<number>();

    const { checkScrollPosition, isAtRight, scrollDivRef } = useRightShadow();
    const { width: viewPortWidth } = useViewportSize();
    const isContainerXOverflowing = useIsOverflow(scrollDivRef, false, [rows, viewPortWidth]);

    const hoverOptionsProps = rows?.find(
        row => row.hoverOptionsProps !== undefined,
    )?.hoverOptionsProps;

    const containerMarginRight = (function () {
        if (!isNaN(hoverOptionsProps?.hoverOptionsWidth) && isContainerXOverflowing) {
            return -hoverOptionsProps.hoverOptionsWidth;
        }

        return undefined;
    })();

    const getStickyStyles = (
        index: number,
        zIndex: number,
        elseZIndex: number,
        stickyColumnsCountParam: number,
        columnWidthsParam: number[],
    ): React.CSSProperties => {
        if (index < stickyColumnsCountParam) {
            const leftPosition = columnWidthsParam
                .slice(0, index)
                .reduce((total, width) => total + width, 0);
            return {
                zIndex,
                left: `${leftPosition}px`,
                position: 'sticky',
            };
        }
        return { zIndex: elseZIndex };
    };

    const getTopPosition = (rowIndex = 0) => rowIndex * headerHeight;

    const getGroupHeadersWidths = (headersData: GroupHeader[]) => {
        const widths = [];
        let currentIndex = 0;

        headersData.forEach(group => {
            const span = group.span;
            let totalWidth = 0;

            for (let i = currentIndex; i < currentIndex + span; i += 1) {
                totalWidth += columnWidths[i];
            }

            widths.push(totalWidth);
            currentIndex += span;
        });
        return widths;
    };

    if (loading) {
        return (
            <div
                style={{
                    height: containerHeight,
                }}
            >
                <Spinner />
            </div>
        );
    }

    if (error) {
        return (
            <div
                style={{
                    height: containerHeight,
                }}
                className="flex items-center justify-center"
            >
                <ErrorText error={error} />
            </div>
        );
    }

    if (isDataEmpty) {
        return (
            <div
                style={{
                    height: containerHeight,
                }}
                className="flex items-center justify-center"
            >
                <div className="text-navy-30 text-lg font-normal">
                    {noDataMessage || 'No Data Available'}
                </div>
            </div>
        );
    }

    return (
        <StyledContainer isAtRight={isAtRight}>
            <div
                ref={scrollDivRef}
                style={{
                    height: containerHeight,
                    marginRight: containerMarginRight,
                }}
                className={cx('overflow-auto relative no-scrollbar', containerClassName)}
                onScroll={checkScrollPosition}
            >
                <table className="min-w-max table-auto border-separate border-spacing-0 text-left">
                    <thead>
                        {groupHeaders &&
                            groupHeaders.map(
                                (
                                    { headersData, stickyColumnsCountData, commonHeaderClasses },
                                    rowIndex,
                                ) => {
                                    const widths = getGroupHeadersWidths(headersData);
                                    return (
                                        <tr key={rowIndex}>
                                            {headersData.map(
                                                ({ span, title, className }, index) => (
                                                    <th
                                                        key={index}
                                                        style={{
                                                            ...getStickyStyles(
                                                                index,
                                                                304,
                                                                303,
                                                                stickyColumnsCountData,
                                                                widths,
                                                            ),
                                                            position: 'sticky',
                                                            top: `${getTopPosition(rowIndex)}px`,
                                                            height: headerHeight,
                                                        }}
                                                        className={cx(
                                                            'py-[10px] px-4 border-navy-10 text-xs text-navy-30 font-medium ',
                                                            commonHeaderClasses,
                                                            className,
                                                        )}
                                                        colSpan={span}
                                                    >
                                                        {title}
                                                    </th>
                                                ),
                                            )}
                                        </tr>
                                    );
                                },
                            )}
                        <tr>
                            {headers.headersData.map(({ title, className }, index) => (
                                <th
                                    key={index}
                                    style={{
                                        ...getStickyStyles(
                                            index,
                                            304,
                                            303,
                                            stickyColumnsCount,
                                            columnWidths,
                                        ),
                                        position: 'sticky',
                                        top: `${getTopPosition(groupHeaders?.length)}px`,
                                        width: columnWidths[index],
                                        height: headerHeight,
                                    }}
                                    className={cx(
                                        'py-[10px] px-4 border-navy-10 text-xs text-navy-30 font-medium',
                                        headers.commonHeaderClasses,
                                        className,
                                    )}
                                >
                                    {title}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {rows?.map(
                            (
                                {
                                    columns,
                                    key,
                                    rowOnClick,
                                    commonColumnClasses,
                                    hoverOptionsProps: rowHoverOptionsProps,
                                },
                                rowIndex,
                            ) => (
                                // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
                                <tr
                                    key={key}
                                    className={cx('group relative', {
                                        'cursor-pointer': !!rowOnClick,
                                    })}
                                    onClick={rowOnClick}
                                    onMouseOver={() => setHoveredRowIndex(rowIndex)}
                                    onMouseLeave={() => setHoveredRowIndex(null)}
                                >
                                    {columns.map(({ tableData, tableDataClassName }, cellIndex) => (
                                        <td
                                            key={cellIndex}
                                            style={{
                                                ...getStickyStyles(
                                                    cellIndex,
                                                    2,
                                                    1,
                                                    stickyColumnsCount,
                                                    columnWidths,
                                                ),
                                                width: columnWidths[cellIndex],
                                                backgroundColor:
                                                    rowIndex === hoveredRowIndex
                                                        ? hoveredRowBgColor
                                                        : null,
                                            }}
                                            className={cx(
                                                'py-[10px] text-sm px-4 border-b border-navy-10 align-top',
                                                commonColumnClasses,
                                                tableDataClassName,
                                            )}
                                        >
                                            {tableData}
                                        </td>
                                    ))}
                                    <RowHoverOptions
                                        rowHoverOptions={rowHoverOptionsProps?.rowHoverOptions}
                                        hoverOptionsWidth={rowHoverOptionsProps?.hoverOptionsWidth}
                                        hoverOptionsRight={
                                            isContainerXOverflowing
                                                ? rowHoverOptionsProps?.hoverOptionsWidth
                                                : 0
                                        }
                                        isContainerXOverflowing={isContainerXOverflowing}
                                    />
                                </tr>
                            ),
                        )}
                    </tbody>
                </table>
            </div>
        </StyledContainer>
    );
};

export default StickyTable;
